.ds_parent_div {
  text-align: center;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.ds_heading {
  margin: auto;
  margin-bottom: 30px;
  width: 60%;
  padding: 10px;
}

.ds_sub_heading {
  font-size: 17px;
  font-weight: 400;
  color: '#454b52';
}

.ds_card_center {
  margin: auto;
  width: 80%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ds_card {
  margin: auto;
  width: 80%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 912px) {
  .ds_card {
    justify-content: center; /* Center cards on small screens */
  }
}

@media (min-width: 913px) {
  .ds_card {
    justify-content: flex-start; /* Align cards to the left on larger screens */
  }
}

.justify-left {
  justify-content: flex-start; /* Align children to the left */
}

.justify-center {
  justify-content: center; /* Center align children */
}

.ds_simple_card {
  margin: 13px;
}

.ds_image {
  width: 100%;
  margin: auto;
  padding: 10px;
  height: 195px;
  align-content: center;
}

.ds_image_tag {
  width: 110px;
  max-height: 185px;
}

.ds_info {
  margin: auto;
  text-align: left;
  padding-left: 20px;
  width: 250px;
  height: 165px;
}

.ds_status {
  width: fit-content;
  margin-bottom: 5px;
}

.ds_data {
  margin-bottom: 5px;
}
.ds_key {
  font-size: 13px;
  font-weight: 600;
}

.ds_value {
  color: #454b52;
  font-size: 13px;
}

.unset_padding {
  padding: unset;
}
