@font-face {
  font-family: 'TESCO Modern-Light';
  src: url('assets/fonts/TESCOModern-Light-final.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Tesco Modern-Bold';
  src: url('assets/fonts/tescomodbol-web.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
