.deviceTooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.deviceTooltip::after {
  content: attr(data-tooltip);
  background: #333;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  bottom: 26px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  position: absolute;
  width: 210px;
  max-width: 210px;
  transform: translateX(auto);
  visibility: hidden;
  text-align: left;
}

.deviceTooltip:hover::after {
  opacity: 1;
  visibility: visible;
}
